import React from 'react';
import { Route } from 'react-router-dom';

const LayoutRoute = ({ contextUtil, component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <contextUtil.AlertContextKey.Consumer>
          {contextAlert => (
            <contextUtil.ConfirmContextKey.Consumer>
              {contextConfirm => (
                <contextUtil.LoaderContextKey.Consumer>
                  {contextLoader => (
                    <Component {...props} alertContext={contextAlert} confirmContext={contextConfirm} loaderContext={contextLoader} />
                  )}
                </contextUtil.LoaderContextKey.Consumer>
              )}
            </contextUtil.ConfirmContextKey.Consumer>
          )}
        </contextUtil.AlertContextKey.Consumer>
      </Layout>
    )}
  />
);

export default LayoutRoute;
