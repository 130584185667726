import React from 'react';
import ReactDOM from 'react-dom';
import "react-datepicker/dist/react-datepicker.css";
import store from "./redux/store/index";
import { Provider } from "react-redux";
import App from './App';
import ContextStore from './utils/Store';
ReactDOM.render(
    <Provider store={store}>
        <ContextStore>
            <App />
        </ContextStore>
    </Provider>
    , 
    document.getElementById('root')
);


