export const getErrorMessage = error => {
  let errorMessage = 'Something went wrong please try again.'; // default error message
  try {
    let status = null;
    errorMessage = error.data.error || errorMessage;
    if (error.response && error.response.status) status = error.response.status;

    switch (status) {
      case 401:
        break;
      case 404:
        errorMessage = 'Server is not reachable. Please contact administrator.';
        break;
      case 403:
        // do something when you're unauthorized to access a resource
        console.log('AUM');
        break;
      case 500:
        // do something when your server exploded
        break;
      default:
      // handle normal errors with some alert or whatever
    }
  } catch (error) {
    // handle error
  }
  return errorMessage;
};

export const defaultDialogProps = (
  title,
  message,
  buttonLabel,
  closeCallback,
) => {
  return {
    open: true,
    alertTitle: title,
    alertMessage: message,
    buttonLabel: buttonLabel,
    onRequestClose: () => {
      closeCallback();
    },
  };
};

export const defaultConfirmDialogProps = (
  title,
  message,
  buttonPositiveLabel,
  buttonNegativeLabel,
  buttonPositiveCall,
  buttonNegativeCall,
) => {
  return {
    open: true,
    alertTitle: title,
    alertMessage: message,
    buttonPositiveLabel: buttonPositiveLabel,
    buttonNegativeLabel: buttonNegativeLabel,
    buttonPositiveCall: () => {
      buttonPositiveCall();
    },
    buttonNegativeCall: () => {
      buttonNegativeCall();
    },
  };
};

export const validateString = str => {
  return str.trim().length > 0 && str.trim().length < 100;
};

export const validateNonNegative = num => {
  return num > 0;
};

export const DateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

export const DateOptions2 = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export let AUTH = { pageList: '', token: "", user: { email: '', userType: "" } };
// USER = { email, userType } (do not store password - bad practice)

export const GlobalAuthSet = authObj => {
  AUTH = authObj;
};

export const invalidTextMessage =
  'Must be less than 255 characters and non empty';

export const invalidNumberMessage = 'Input must be a greater than 0';
