import {
    ADD_INSURANCE_COMPANY,
    ADD_ALLERGY_TYPE,
    ADD_APP_ACCESS_TOKEN,
    ADD_BLOOD_GROUP,
    ADD_CARE_MANAGER,
    ADD_CITY,
    ADD_COMPANY,
    ADD_CONDITION_TYPE,
    ADD_COUNTRY,
    ADD_DIETICIAN,
    ADD_DIET_PLAN,
    ADD_DOCTOR,
    ADD_HOSPITAL,
    ADD_REFER_BY_TYPE,
    ADD_STATE,
    ADD_SURGERY_TYPE,
    ADD_USER_TYPE,
    ADD_VITAL_TYPE
} from "../constants/action-types";

const initialState = {
    insuranceCompanyList: [],
    allergyTypeList: [],
    appAccessTokenList: [],
    bloodGroupList: [],
    careManagerList: [],
    cityList: [],
    companyList: [],
    conditionTypeList: [],
    countryList: [],
    dieticianList: [],
    dietPlanList: [],
    doctorList: [],
    hospitalList: [],
    referByTypeList: [],
    stateList: [],
    surgeryTypeList: [],
    userTypeList: [],
    vitalTypeList: []
}

function rootReducer(state = initialState, action) {

    if (action.type === ADD_INSURANCE_COMPANY) {
        return Object.assign({}, state, {
            insuranceCompanyList: state.insuranceCompanyList.concat(action.payload)
        });
    }

    else if (action.type === ADD_ALLERGY_TYPE) {
        return Object.assign({}, state, {
            allergyTypeList: state.allergyTypeList.concat(action.payload)
        });
    }

    else if (action.type === ADD_APP_ACCESS_TOKEN) {
        return Object.assign({}, state, {
            appAccessTokenList: state.appAccessTokenList.concat(action.payload)
        });
    }

    else if (action.type === ADD_BLOOD_GROUP) {
        return Object.assign({}, state, {
            bloodGroupList: state.bloodGroupList.concat(action.payload)
        });
    }

    else if (action.type === ADD_CARE_MANAGER) {
        return Object.assign({}, state, {
            careManagerList: state.careManagerList.concat(action.payload)
        });
    }

    else if (action.type === ADD_CITY) {
        return Object.assign({}, state, {
            cityList: state.cityList.concat(action.payload)
        });
    }

    else if (action.type === ADD_COMPANY) {
        return Object.assign({}, state, {
            companyList: state.companyList.concat(action.payload)
        });
    }

    else if (action.type === ADD_CONDITION_TYPE) {
        return Object.assign({}, state, {
            conditionTypeList: state.conditionTypeList.concat(action.payload)
        });
    }

    else if (action.type === ADD_COUNTRY) {
        return Object.assign({}, state, {
            countryList: state.countryList.concat(action.payload)
        });
    }

    else if (action.type === ADD_DIETICIAN) {
        return Object.assign({}, state, {
            dieticianList: state.dieticianList.concat(action.payload)
        });
    }

    else if (action.type === ADD_DIET_PLAN) {
        return Object.assign({}, state, {
            dietPlanList: state.dietPlanList.concat(action.payload)
        });
    }

    else if (action.type === ADD_DOCTOR) {
        return Object.assign({}, state, {
            doctorList: state.doctorList.concat(action.payload)
        });
    }

    else if (action.type === ADD_HOSPITAL) {
        return Object.assign({}, state, {
            hospitalList: state.hospitalList.concat(action.payload)
        });
    }

    else if (action.type === ADD_REFER_BY_TYPE) {
        return Object.assign({}, state, {
            referByTypeList: state.referByTypeList.concat(action.payload)
        });
    }

    else if (action.type === ADD_STATE) {
        return Object.assign({}, state, {
            stateList: state.stateList.concat(action.payload)
        });
    }

    else if (action.type === ADD_SURGERY_TYPE) {
        return Object.assign({}, state, {
            surgeryTypeList: state.surgeryTypeList.concat(action.payload)
        });
    }

    else if (action.type === ADD_USER_TYPE) {
        return Object.assign({}, state, {
            userTypeList: state.userTypeList.concat(action.payload)
        });
    }

    else if (action.type === ADD_VITAL_TYPE) {
        return Object.assign({}, state, {
            vitalTypeList: state.vitalTypeList.concat(action.payload)
        });
    }
    return state;
}

export default rootReducer;