import Avatar from '../Avatar';
import React, { Component } from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { GlobalAuthSet } from '../../utils/Util';

const bem = bn.create('header');

class Header extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isOpenNotificationPopover: false,
      isNotificationConfirmed: false,
      isOpenUserCardPopover: false,
    };
  }

  toggleNotificationPopover = () => {
    this.setState({ isOpenNotificationPopover: !this.state.isOpenNotificationPopover });

    if (!this.state.isNotificationConfirmed) this.setState({ isNotificationConfirmed: true });
  };

  toggleUserCardPopover = () => this.setState({ isOpenUserCardPopover: !this.state.isOpenUserCardPopover });

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  signOut = () => {
    GlobalAuthSet({ PAGES: null, TOKEN: null, });
    window.location.href = '/';
    // this.props.history.push('/'); // better use if available in future
  }

  render() {
    return (
      <Navbar light expand className={bem.b('headerClass')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
         

        <Nav navbar className={bem.e('nav-right')}> 
          <NavItem>
            <NavLink id="Popover2">
              <Avatar onClick={this.toggleUserCardPopover} className="can-click" />
            </NavLink>

            <Popover
              className="p-0 border-0"
              isOpen={this.state.isOpenUserCardPopover}
              placement="bottom-end"
              style={{ minWidth: 250 }}
              target="Popover2" 
              toggle={this.toggleUserCardPopover} >
              <PopoverBody className="p-0 border-light"> 
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdPersonPin /> Profile
                    </ListGroupItem>

                    <ListGroupItem tag="button" action className="border-light">
                      <MdInsertChart /> Stats
                    </ListGroupItem>
                    
                    <ListGroupItem tag="button" action className="border-light">
                      <MdMessage /> Messages
                    </ListGroupItem>
                    
                    <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem>
                    
                    <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                    </ListGroupItem>
                    
                    <ListGroupItem tag="button" action className="border-light" onClick={this.signOut}>
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup> 
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
