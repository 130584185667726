import React, { useContext } from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import PageWrapper from './components/PageWrapper';

import { AlertContext, ConfirmContext, LoaderContext } from './utils/Store';
import LoginPage from '././pages/Login';
import { AUTH } from './utils/Util';
import CreatePlan from './pages/AccessToken/CreatePlan';

//Dashboard
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const ChangeAdminPasswordPage = React.lazy(() =>
  import('./pages/ChangeAdminPasswordPage'),
);
//contact
const ContactUsPage = React.lazy(() => import('./pages/Users/ContactUS'));
//WEB CONTACT fetchConatctUSInfo
const WebContacts = React.lazy(() => import('./pages/WebsiteUser/WebContacts'));
const LabReportParser = React.lazy(() => import('./pages/labReportParser'));
const DieticianUserMappingPage = React.lazy(() => import('./pages/DieticianUserMapping'));




// Users
const NotificationLogsPage = React.lazy(() =>
  import('./pages/Users/NotificationLogs'),
);
const ViewUsersPage = React.lazy(() => import('./pages/Users/ViewUsersPage'));
const ViewDeletedUser = React.lazy(() => import('./pages/Users/DeletedUser'));

const ViewUserDetailsPage = React.lazy(() =>
  import('./pages/Users/ViewUserDetailsPage'),
);
const ViewUnauthenticateUsers = React.lazy(() =>
  import('./pages/Users/UnauthenticateUsers'),
);
const ViewDeviceLogs = React.lazy(() => import('./pages/Users/ViewDeviceLogs'));

const UnauthorizedPage = React.lazy(() => import('./pages/UnauthorizedPage'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));

const AddGlobalAppAccessToken = React.lazy(() =>
  import('./pages/AddGlobalAppAccessToken'),
);
const AddGlobalDieticianPage = React.lazy(() =>
  import('./pages/AddGlobalDieticianPage'),
);
const AddGlobalCareManagerPage = React.lazy(() =>
  import('./pages/AddGlobalCareManagerPage'),
);
const AddGlobalReferByTypePage = React.lazy(() =>
  import('./pages/AddGlobalReferByTypePage'),
);
const AddGlobalCountryPage = React.lazy(() =>
  import('./pages/AddGlobalCountryPage'),
);
const AddGlobalInsuranceCompanyPage = React.lazy(() =>
  import('./pages/AddGlobalInsuranceCompanyPage'),
);
const AddGlobalConditionTypePage = React.lazy(() =>
  import('./pages/AddGlobalConditionTypePage'),
);
const AssignDietPlan = React.lazy(() =>
  import('./pages/AssignDietPlan/AssignDietPlan'),
); // Ishanh2000 working here.
const DietPlanNamePage = React.lazy(() =>
  import('./pages/DietPlanName/DietPlanName'),
);

const GoalGroupPage = React.lazy(() => import('./pages/GoalGroup/GoalGroup'));

const CreateFood = React.lazy(() => import('./pages/Food/CreateFood')); // Ishanh2000 working here.
const EditFood = React.lazy(() => import('./pages/Food/EditFood')); // Ishanh2000 working here.
const AddGlobalDoctorPage = React.lazy(() =>
  import('./pages/AddGlobalDoctorPage'),
);
const AddGlobalHospitalPage = React.lazy(() =>
  import('./pages/AddGlobalHospitalPage'),
);
const AddGlobalStatePage = React.lazy(() =>
  import('./pages/AddGlobalStatePage'),
);
const AddGlobalSurgeryTypePage = React.lazy(() =>
  import('./pages/AddGlobalSurgeryTypePage'),
);
const AddGlobalUserTypePage = React.lazy(() =>
  import('./pages/AddGlobalUserTypePage'),
);
const AddGlobalVitalTypePage = React.lazy(() =>
  import('./pages/AddGlobalVitalTypePage'),
);
const AddGlobalAllergyTypePage = React.lazy(() =>
  import('./pages/AddGlobalAllergyTypePage'),
);
const AddGlobalBloodGroupPage = React.lazy(() =>
  import('./pages/AddGlobalBloodGroupPage'),
);
const AddGlobalCityPage = React.lazy(() => import('./pages/AddGlobalCityPage'));
const AddGlobalCompanyPage = React.lazy(() =>
  import('./pages/AddGlobalCompanyPage'),
);

const BadgePage = React.lazy(() => import('./pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('./pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('./pages/ButtonPage'));
const CardPage = React.lazy(() => import('./pages/CardPage'));
const ChartPage = React.lazy(() => import('./pages/ChartPage'));
const DropdownPage = React.lazy(() => import('./pages/DropdownPage'));
const FormPage = React.lazy(() => import('./pages/FormPage'));
const InputGroupPage = React.lazy(() => import('./pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('./pages/ModalPage'));
const ProgressPage = React.lazy(() => import('./pages/ProgressPage'));
const TablePage = React.lazy(() => import('./pages/TablePage'));
const TypographyPage = React.lazy(() => import('./pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('./pages/WidgetPage'));

// Doctors
const ReferDoctorPage = React.lazy(() =>
  import('./pages/Doctors/ReferDoctorPage'),
);
const AddDoctorsPage = React.lazy(() => import('./pages/Doctors/AddDoctor'));
const DoctorDetailsPage = React.lazy(() => import('./pages/Doctors/DoctorDetails'));
const ViewDoctorsPage = React.lazy(() => import('./pages/Doctors/ViewDoctors'));

const ViewPaymentLogs = React.lazy(() => import('./pages/Doctors/paymentLogs'));
const PatientDoctorMappingPage = React.lazy(() =>
  import('./pages/Doctors/PatientDoctorMapping'),
);
const DoctorPatientMappingPage = React.lazy(() =>
  import('./pages/Doctors/DoctorPatientMapping'),
);

const AlertDialog = React.lazy(() => import('./components/Alert'));
const ConfirmDialog = React.lazy(() => import('./components/ConfirmDialog'));
const LoaderComponent = React.lazy(() => import('./components/Loader'));

// Diet Plan Routes
const AddGlobalDietPlanPage = React.lazy(() =>
  import('./pages/AddGlobalDietPlanPage'),
);
const AssignDietPlanPage = React.lazy(() =>
  import('./pages/AssignDietPlan/AssignDietPlan'),
);
const CreateDietPlanPage = React.lazy(() =>
  import('./pages/CreateDietPlan/CreateDietPlan'),
);

//Food Related Routes
const FoodListPage = React.lazy(() => import('./pages/Food/EditFood'));
const CreateFoodPage = React.lazy(() => import('./pages/Food/CreateFood'));

// Hospitals
const ViewHospitals = React.lazy(() => import('./pages/Hospital/ViewHospital'));
const AddHospitals = React.lazy(() => import('./pages/Hospital/AddHospital'));

// website user

const ViewWebsiteUser = React.lazy(() =>
  import('./pages/WebsiteUser/ViewWebsiteUsers'),
);

//Reminder

const ViewReminderLogs = React.lazy(() =>
  import('./pages/Users/ViewReminderLogs'),
);

//Goal_Groups

const GoalsPage = React.lazy(() => import('./pages/Goal_Groups/CreateGoal'));
const AssignGoalsToGroupsPage = React.lazy(() =>
  import('./pages/GoalGroup/AssignGoalsToGroup'),
);
const AssignGroupToUsers = React.lazy(() =>
  import('./pages/GoalGroup/AssingGoalsToUser'),
);
const ViewUserGroup = React.lazy(() =>
  import('./pages/GoalGroup/ViewUserAndGoals'),
);

// Access Token Routes
const CreatePlanPage = React.lazy(() =>
  import('./pages/AccessToken/CreatePlan'),
);
const PlanCategoryPage = React.lazy(() =>
  import('./pages/AccessToken/PlanCategory'),
);
const CreateRebateCouponPage = React.lazy(() =>
  import('./pages/AccessToken/CreateRebateCoupon'),
);

const CreateTokenPage = React.lazy(() =>
  import('./pages/AccessToken/CreateAppAccessToken'),
);

const CreateReferalCodePage = React.lazy(() =>
  import('./pages/ReferalCode/Referel'),
);

const CreateChannelPage = React.lazy(() =>
  import('./pages/ReferalCode/CreateChannel'),
);

//sms logs
const ViewSmsLogs = React.lazy(() => import('./pages/AuthSms/viewSmsLogs'));

//Backend User
const ViewBackendUser = React.lazy(() =>
  import('./pages/BackendUser/viewBackendUser'),
);
const AddBackendUser = React.lazy(() =>
  import('./pages/BackendUser/addBackendUser'),
);
const ManageAccessPage = React.lazy(() =>
  import('./pages/BackendUser/ManageAccess'),
);

// Blogs Route
const BlogPage = React.lazy(() => import('./pages/BlogPage'));

// Yoga Session
const YogaSessionPage = React.lazy(() =>
  import('./pages/yogaSession/yogaSession'),
);
const YogaSessionLogsPage = React.lazy(() =>
  import('./pages/yogaSession/yogaSessionLogs'),
);

//Diet Consulation

const DietConsultationSessionPage = React.lazy(() =>
  import('./pages/DietConsultantSession'),
);
const CreateQuizPage = React.lazy(() =>
  import('./pages/Quiz/CreateQuiz'),
);
const AssignQuizPage = React.lazy(() =>
  import('./pages/Quiz/AssignQuiz'),
);
const QuizResultsPage = React.lazy(() =>
  import('./pages/Quiz/QuizResults'),
);
const CreateRewardsPage = React.lazy(() =>
  import('./pages/Reward/CreateReward'),
);

//Gamification
const HealthQuestionsPage = React.lazy(() => import('./pages/HeatlthScores/healthQuestions'));
const VideoSeriesPage = React.lazy(() => import('./pages/VideoSeries/VideoSeries'));
const AddVideosToSeriesPage = React.lazy(() => import('./pages/VideoSeries/AddVideosToSeries'));
const PointCategoryPage = React.lazy(() => import('./pages/Points/PointCategory'));
const PointValuePage = React.lazy(() => import('./pages/Points/PointValue'));

//Ecommerce
const ProductCategoryPage = React.lazy(() => import('./pages/Products/ProductCategory'));
const AddProductPage = React.lazy(() => import('./pages/Products/AddProduct'));

// Challenges
const ChallengesPages = React.lazy(() => import('./pages/Challenges/Challenges'))
const ChallengesUserListPages = React.lazy(() => import('./pages/Challenges/ChallengesUserList'))

const SensorCodes = React.lazy(() => import('./pages/SensorCodes/SensorCodes'));
const SensorSalesDetail = React.lazy(() => import('./pages/SensorSales/SensorSales'));
const SensorErrorDetails = React.lazy(() => import('./pages/SensorSales/SensorError'));
const SensorCodesDownload = React.lazy(() => import('./pages/SensorCodes/downloadCodes'));
const PromotionalEmail = React.lazy(() => import('./pages/PromotionEmail'));
const PromotionalWhatsapp = React.lazy(() => import('./pages/PromotionEmail/PromotionalWhatsapp'));
const GetRemainSensorCount = React.lazy(() => import('./pages/SensorCount/SensorCount'));
const ViewPolicy = React.lazy(() => import('./pages/TermsAndPrivacyPolicy/ViewPolicy'));



const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};





const App = props => {
  const { user } = AUTH;
  let userType = null;
  if (user) {
    userType = user.userType;
  }
  const [alertProps /* setAlertProps */] = useContext(AlertContext),
    [confirmProps /* setConfirmProps */] = useContext(ConfirmContext),
    [loaderProps /* setLoaderVisibility */] = useContext(LoaderContext),
    utilContext = {
      AlertContextKey: AlertContext,
      ConfirmContextKey: ConfirmContext,
      LoaderContextKey: LoaderContext,
    };

  return (
    <React.Suspense fallback={<PageSpinner />}>
      <AlertDialog {...alertProps} />
      <ConfirmDialog {...confirmProps} />
      <LoaderComponent {...loaderProps} />

      <BrowserRouter basename={getBasename()}>
        <Switch>
          <LayoutRoute
            exact
            path="/"
            layout={EmptyLayout}
            contextUtil={utilContext}
            component={LoginPage}
          />
          <LayoutRoute
            exact
            path="/unauthorized"
            layout={EmptyLayout}
            contextUtil={utilContext}
            component={UnauthorizedPage}
          />
          {/* <Route exact path="/unauthorized" render={() => <UnauthorizedPage />} /> */}


          <MainLayout breakpoint={props.breakpoint}>
            {userType === 'administrator' && (
              <>
                {/* UTILITIES */}
                <Route
                  exact
                  path="/dashboard"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={DashboardPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_Deleted_Users"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewDeletedUser}
                    />
                  )}
                />
                <Route
                  exact
                  path="/viewTermsAndCondition"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewPolicy}
                    />
                  )}
                />
                <Route
                  exact
                  path="/viewPrivacyPolicy"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewPolicy}
                    />
                  )}
                />
                <Route
                  exact
                  path="/lab_report_parser"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={LabReportParser}
                    />
                  )}
                />
                <Route
                  exact
                  path="/dietician_user_mapping"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={DieticianUserMappingPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/create_quiz"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateQuizPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/assign_quiz"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AssignQuizPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/quiz_results"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={QuizResultsPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/create_rewards"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateRewardsPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/health_questions"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={HealthQuestionsPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/point_category"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={PointCategoryPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/point_value"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={PointValuePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/product_category"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ProductCategoryPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_product"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddProductPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/video_series"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={VideoSeriesPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/challenges"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ChallengesPages}
                    />
                  )}
                />
                <Route
                  exact
                  path="/challenges_userlist"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ChallengesUserListPages}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_videos_to_series"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddVideosToSeriesPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/change_admin_password"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ChangeAdminPasswordPage}
                    />
                  )}
                />

                <Route
                  exact
                  path="/notification_logs"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={NotificationLogsPage}
                    />
                  )}
                />

                <Route
                  exact
                  path="/view_device_log"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewDeviceLogs}
                    />
                  )}
                />
                <Route
                  exact
                  path="/web_contacts"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={WebContacts}
                    />
                  )}
                />
                <Route
                  exact
                  path="/contact_us_info"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ContactUsPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_unauthenticate_user"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewUnauthenticateUsers}
                      isDoctor={false}
                    />
                  )}
                />

                {/* MAIN TABLES */}
                <Route
                  exact
                  path="/add_refer_by_type"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalReferByTypePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_country"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalCountryPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_insurance_company"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalInsuranceCompanyPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_condition_type"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalConditionTypePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_hospital"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalHospitalPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_state"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalStatePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_surgery_type"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalSurgeryTypePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_user_type"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalUserTypePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_vital_type"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalVitalTypePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_allergy_type"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalAllergyTypePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_blood_group"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalBloodGroupPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_city"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalCityPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_company"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalCompanyPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_doctor"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalDoctorPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_care_manager"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalCareManagerPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_app_access_token"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalAppAccessToken}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_backend_user"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewBackendUser}
                    />
                  )}
                />
                <Route
                  exact
                  path="/manage_access"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ManageAccessPage}
                    />
                  )}
                />

                <Route
                  exact
                  path="/add_backend_user"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddBackendUser}
                    />
                  )}
                />

                {/* access token routes */}

                <Route
                  exact
                  path="/add_plan"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreatePlanPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_plan_category"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={PlanCategoryPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_rebate_coupon"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateRebateCouponPage}
                    />
                  )}
                />

                <Route
                  exact
                  path="/referal_code"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateReferalCodePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/create_channel"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateChannelPage}
                    />
                  )}
                />

                <Route
                  exact
                  path="/add_access_token"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateTokenPage}
                    />
                  )}
                />

                {/* sms logs */}

              

                {/* doctor payment logs */}

                <Route
                  exact
                  path="/view_payment_logs"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewPaymentLogs}
                    />
                  )}
                />
                {/* Reminder */}
                <Route
                  exact
                  path="/view_reminder_log"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewReminderLogs}
                    />
                  )}
                />

                <Route
                  exact
                  path="/add_hospital"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddHospitals}
                    />
                  )}
                />
                {/* DESIGN */}
                <Route
                  exact
                  path="/buttons"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ButtonPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/cards"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CardPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/widgets"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={WidgetPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/typography"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={TypographyPage}
                    />
                  )}
                />

                <Route
                  exact
                  path="/tables"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={TablePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/badges"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={BadgePage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/button-groups"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ButtonGroupPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/dropdowns"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={DropdownPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/progress"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ProgressPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/modals"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ModalPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/forms"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={FormPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/input-groups"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={InputGroupPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/charts"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ChartPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_refer_doctor"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ReferDoctorPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_doctor"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddDoctorsPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_doctor"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewDoctorsPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/doctor_details"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={DoctorDetailsPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_unauthenticate_doctors"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewUnauthenticateUsers}
                      isDoctor={true}
                    />)}
                />
                <Route
                  exact
                  path="/patient_doctor_mapping"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={PatientDoctorMappingPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/doctor_patient_mapping"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={DoctorPatientMappingPage}
                    />
                  )}
                />

                {/* <Route exact path="/charts"
                render={props => <PageWrapper {...props} contextUtil={utilContext} component={ChartPage} />} /> */}
              </>
            )}

            {/* dietician Routes */}

            {userType === 'Website Admin' && (
              <>
                <Route
                  exact
                  path="/create_quiz"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateQuizPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/assign_quiz"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AssignQuizPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/quiz_results"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={QuizResultsPage}
                    />
                  )}
                />
              </>
            )}
            {userType === 'Food Admin' && (
              <>
                <Route
                  exact
                  path="/add_g_view_food"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={FoodListPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_add_food"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateFoodPage}
                    />
                  )}
                />
              </>
            )}

            {(userType === 'dietician' || userType === 'administrator') && (
              <>
                <Route
                  exact
                  path="/view_sms_logs"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewSmsLogs}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_users"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewUsersPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_user_details"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewUserDetailsPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_diet_plan"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalDietPlanPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_add_diet_plan"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AssignDietPlanPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_create_diet_plan"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AssignDietPlanPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_view_food"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={FoodListPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_add_food"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateFoodPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_create_or_edit_a_new_diet_plan"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateDietPlanPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_diet_plan_name"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={DietPlanNamePage}
                    />
                  )}
                />

                <Route
                  exact
                  path="/AssignDietPlan"
                  render={() => <AssignDietPlan />}
                />
                <Route exact path="/CreateFood" render={() => <CreateFood />} />
                <Route exact path="/EditFood" render={() => <EditFood />} />
                <Route
                  exact
                  path="/add_g_dietician"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalDieticianPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_diet_consultation_session"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={DietConsultationSessionPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_hospital"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewHospitals}
                    />
                  )}
                />

              </>
            )}

            {/*external_dietician routes*/}
            {(userType === 'external_dietician') && (
              <>
                <Route
                  exact
                  path="/add_g_diet_plan"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AddGlobalDietPlanPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_add_diet_plan"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AssignDietPlanPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_create_diet_plan"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={AssignDietPlanPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_create_or_edit_a_new_diet_plan"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={CreateDietPlanPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/add_g_diet_plan_name"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={DietPlanNamePage}
                    />
                  )}
                />

                <Route
                  exact
                  path="/AssignDietPlan"
                  render={() => <AssignDietPlan />}
                />
                <Route
                  exact
                  path="/view_users"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewUsersPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/view_user_details"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewUserDetailsPage}
                    />
                  )}
                />
              </>
            )}

            {/* yoga Instructor routes */}
            {(userType === 'dietician' ||
              userType === 'administrator' ||
              userType === 'yoga Instructor') && (
                <>
                  {/* yoga Session */}
                  <Route
                    exact
                    path="/view_yoga_session"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={YogaSessionPage}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/view_yoga_session_logs"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={YogaSessionLogsPage}
                      />
                    )}
                  />
                </>
              )}

            {/* website admin routes */}

            {(userType === 'administrator' || userType === 'Website Admin') && (
              <>
                {/* website user */}
                <Route
                  exact
                  path="/website_users"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={ViewWebsiteUser}
                    />
                  )}
                />
                {/* Blog  */}
                <Route
                  exact
                  path="/view_blog"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={BlogPage}
                    />
                  )}
                />
                <Route
                  exact
                  path="/getSensorCount"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={GetRemainSensorCount}
                    />
                  )}
                />
                <Route
                  exact
                  path="/sendEmail"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={PromotionalEmail}
                    />
                  )}
                />
                <Route
                  exact
                  path="/sendWhatsapp"
                  render={props => (
                    <PageWrapper
                      {...props}
                      contextUtil={utilContext}
                      component={PromotionalWhatsapp}
                    />
                  )}
                />
              </>
            )}
            {/* Goal Groups Routes */}

            {(userType === 'administrator' ||
              userType === 'Fitness Instructor' || userType === 'dietician') && (
                <>
                  {/* Goal_Groups */}

                  <Route
                    exact
                    path="/add_g_goals"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={GoalsPage}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/goal_groups"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={GoalGroupPage}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/assgn_goal_to_group"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={AssignGoalsToGroupsPage}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/assgn_group_to_user"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={AssignGroupToUsers}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/view_user_group_mapping"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={ViewUserGroup}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/sensor_activation"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={SensorCodes}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/sensorError"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={SensorErrorDetails}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/downloadSensorCodes"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={SensorCodesDownload}
                      />
                    )}
                  />
                </>
              )}
            {(userType === 'administrator' ||
              userType === 'order_management' || userType === 'dietician') && (
                <>
                  <Route
                    exact
                    path="/sensor_sales"
                    render={props => (
                      <PageWrapper
                        {...props}
                        contextUtil={utilContext}
                        component={SensorSalesDetail}
                      />
                    )}
                  />
                </>
              )}


          </MainLayout>

          {/* <Redirect to="/" /> */}
          {/* <LayoutRoute exact path="/unauthorized" layout={EmptyLayout} contextUtil={utilContext} component={UnauthorizedPage} /> */}
          {/* <LayoutRoute path="*" layout={EmptyLayout} contextUtil={utilContext} component={NotFoundPage} /> */}
        </Switch>
      </BrowserRouter>
    </React.Suspense>
  );
};

const query = ({ width }) => {
  if (width <= 575) return { breakpoint: 'xs' };
  if (576 <= width && width <= 767) return { breakpoint: 'sm' };
  if (768 <= width && width <= 991) return { breakpoint: 'md' };
  if (992 <= width && width <= 1199) return { breakpoint: 'lg' };
  if (width >= 1200) return { breakpoint: 'xl' };
  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
