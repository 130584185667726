import React, { useState, useCallback } from 'react';
export const AlertContext = React.createContext("");
export const ConfirmContext = React.createContext("");
export const LoaderContext = React.createContext("");

export default function Store({ children }) {
  const
    defaultDialogProps = {
      open: false,
      alertTitle: '',
      alertMessage: '',
      buttonLabel: '',
      onRequestClose: () => { },
    },
    [message, setDialogVisibility] = useState(defaultDialogProps),
    defaultConfirmDialogProps = {
      open: false,
      alertTitle: '',
      alertMessage: '',
      buttonPositiveLabel: '',
      buttonNegativeLabel: "",
      buttonPositiveCall: () => { },
      buttonNegativeCall: () => { },
    },
    [confirmMessage, setConfirmDialogVisibility] = useState(defaultConfirmDialogProps),
    [loaderValue, setLoaderVisiblity] = useState({ open: false });

  return (
    <AlertContext.Provider value={[message, setDialogVisibility]}>
      <ConfirmContext.Provider value={[confirmMessage, setConfirmDialogVisibility]}>
        <LoaderContext.Provider value={[loaderValue, setLoaderVisiblity]}>
          {children}
        </LoaderContext.Provider>
      </ConfirmContext.Provider>
    </AlertContext.Provider>
  );
}
