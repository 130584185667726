import React, { Component } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import logoImage from '../assets/img/logo/logo.png';
import { initAPICall } from '../utils/ServiceCalls'
import { defaultDialogProps, getErrorMessage, GlobalAuthSet } from '../utils/Util';


import { connect } from 'react-redux';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '', isInvalidEmail: false,
      password: '', isInvalidPassword: false,
      userType: '', isInvalidUserType: false,

      userTypeList: [],
    }
  }

  componentWillMount() {
    GlobalAuthSet({ pageList: null, token: null, user: null });
    this.getUserTypeList();
  }

  getUserTypeList = async () => {
    this.props.loaderContext[1]({ open: true });
    initAPICall({
      path: 'zivovUnAuthenticatedRoutes/fetchenum',
      method: 'get',
    })
      .then(({ data }) => { // expected: array
        let userTypeList = [];
        for (var i in data.response.message) {
          userTypeList.push({ id: data.response.message[i], type: data.response.message[i] });
        }
        this.setState({ userTypeList });
      })
      .catch(error => {
        console.log("err",error);
        const alertMessage = getErrorMessage(error);
        const errorObj = defaultDialogProps("Error", alertMessage, "OK", () => { });
        this.props.alertContext[1](errorObj);
      })
      .finally(() => this.props.loaderContext[1]({ open: false }));
  }

  handleChange = event => this.setState({ [event.target.name]: event.target.value });

  handleSubmit = async () => {
    if (this.isInvalidForm()) return;

    const { email, password, userType } = this.state;
    this.props.loaderContext[1]({ open: true });
    initAPICall({
      path: 'zivovUnAuthenticatedRoutes/validateAdmin',
      method: 'post',
      params: { userEmail: email, Password: password, userType },
    })
      .then(({ data }) => {
        // returns { pagesList: string or array, token: string } or { error: string }
        if (data.error) {
          const alertMessage = getErrorMessage(data.error);
          const errorObj = defaultDialogProps("Error", alertMessage, "OK", () => { })
          this.props.alertContext[1](errorObj);
        } else {
          GlobalAuthSet({
            pageList: 'all',
            token: data.response,
            user: { email, userType },
          });
          this.props.history.push('/dashboard');
        }
      })
      .catch(error => {
        const alertMessage = getErrorMessage(error);
        const errorObj = defaultDialogProps("Error", alertMessage, "OK", () => { })
        this.props.alertContext[1](errorObj);
      })
      .finally(() => this.props.loaderContext[1]({ open: false }));
  }

  isInvalidForm = () => {
    const { email, password, userType } = this.state;
    let newState = {}, inValid = false; // do not change order with respect to || operator
    inValid = (newState.isInvalidEmail = !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email))) || inValid; // check
    inValid = (newState.isInvalidPassword = !password) || inValid;
    inValid = (newState.isInvalidUserType = !userType) || inValid;
    this.setState(newState);
    return inValid;
  }

  render() {
    const {
      email, isInvalidEmail,
      password, isInvalidPassword,
      userType, isInvalidUserType,
      userTypeList, } = this.state;
    return (
      <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        <Col md={6} lg={4}>
          <Card body>
            <Form>
              {/* ZIVOV Logo */}
              <div className="text-center pb-4">
                <img src={logoImage} className="rounded" alt="logo"
                  style={{ width: 60, height: 60, cursor: 'pointer' }} />
              </div>

              {/* Email Field */}
              <FormGroup>
                <Label for="email">Email</Label>
                <Input type="email" name="email" placeholder="Enter your email address . . ."
                  value={email} invalid={isInvalidEmail} onChange={this.handleChange} />
                <FormFeedback>Please enter a valid email.</FormFeedback>
              </FormGroup>

              {/* Password Field */}
              <FormGroup>
                <Label for="password">Password</Label>
                <Input type="password" name="password" placeholder="Enter your password . . ."
                  value={password} invalid={isInvalidPassword} onChange={this.handleChange} />
                <FormFeedback>Please enter your password.</FormFeedback>
              </FormGroup>

              {/* User Type Field */}
              <FormGroup>
                <Label for="userType">Select your role . . .</Label>
                <Input type="select" name="userType"
                  invalid={isInvalidUserType} value={userType} onChange={this.handleChange}>
                  <option value="">Select your role . . .</option>
                  {userTypeList.map((uT, index) => <option key={index} value={uT.id}>{uT.type.charAt(0).toUpperCase() + uT.type.slice(1)}</option>)}
                </Input>
                <FormFeedback>Please select your role.</FormFeedback>
              </FormGroup>
              <hr />

              <Button type="button" size="lg" block onClick={this.handleSubmit}>Login</Button>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}
export default LoginPage;