import Page from '../../components/Page';
import React, { PureComponent } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  Col,
  Form,
  UncontrolledTooltip,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { MdContentCopy, MdEdit } from 'react-icons/md';

// for API calls and PageWrapper messages
import { initAPICall } from '../../utils/ServiceCalls';
import {
  getErrorMessage,
  DateOptions2,
  validateString,
  validateNonNegative,
  invalidNumberMessage,
  invalidTextMessage,
} from '../../utils/Util';
import { AUTH, defaultDialogProps } from '../../utils/Util';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PATHS = {
  GET: 'getAppAccessToken',
  INSERT: 'insertAppAccessToken',
  UPDATE: 'updateAppAccessToken',
};

class CreatePlan extends PureComponent {
  constructor(props) {
    super(props);
    this.pageSize = 10;
    this.state = {
      showSideBar: false,
      editIndex: -1,
      name: '',
      isInvalidName: false,
      description: '',
      isInvalidDescription: false,
      days: 0,
      isInvalidDays: false,
      amount: '',
      isInvalidAmount: false,
      is_valid: false,
      is_forever: false,
      planList: [],
      currentPage: 0,
      pagesCount: 0,
      planCategoryList: [],
      fk_cat_id: '',
      isInvalidPlanCatId: false,
      doctorList:[],
      selectedDoctor:null,
      warnDoctorNotSpecified:false,
      isLoadingTypeahead:false,
      modal: false,
      modalData: [],
    };
  }

  handleSearch = query => {
    this.setState({ isLoadingTypeahead: true });
    initAPICall({
      path: 'zivovAuthenticatedRoutes/get_doctor',
      method: 'get',
      params: { searchStr: query },
    })
      .then(({ data }) => {
        //console.log(data);
        const doctorList = data.response.doctors
          ? data.response.doctors.map(doctor => ({
              login: doctor.name,
              id: doctor.id,
               }))
          : []; // for now, one field only
        this.setState({ doctorList, isLoadingTypeahead: false });
      })
      .catch(error => {
        const alertMessage = getErrorMessage(error);
        const errorObj = defaultDialogProps(
          'Error',
          alertMessage,
          'OK',
          () => {},
        );
        this.props.alertContext[1](errorObj);
      });
  };

  handleSelectedDoctor = doctorList => {
    if (doctorList.length>0) {
      //console.log('clicked');
      this.setState({ selectedDoctor: doctorList[0] });
    } else this.setState({ selectedDoctor: null });
  };

  typeaheadRef = null;
  filterBy = () => true;

  modalToggle = indx => {
    if (this.state.modal) {
      this.setState({ modal: false });
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  };

  
  getPlanCategoryList = async (offset = 0) => {
    this.props.loaderContext[1]({ open: true });
    const requestObj = {
      path: `zivovAuthenticatedRoutes/fetch_plan_categories`,
      method: 'POST',
      params: { offset: offset, limit: 100 }, //please correct it
    };
    initAPICall(requestObj)
      .then(succResp => {
        console.log(succResp);
        this.setState({
          planCategoryList: succResp.data.response.data,
        });
      })
      .catch(error => {
        const alertMessage = getErrorMessage(error);
        const errorObj = defaultDialogProps(
          'Error',
          alertMessage,
          'OK',
          () => {},
        ); // last is callback function
        this.props.alertContext[1](errorObj);
      })
      .finally(() => {
        this.props.loaderContext[1]({ open: false });
      });
  };

  resetFormFields = () => {
    this.setState({
      showSideBar: false,
      editIndex: -1,
      name: '',
      isInvalidName: false,
      description: '',
      isInvalidDescription: false,
      days: '0',
      isInvalidDays: false,
      amount: '',
      isInvalidAmount: false,
      is_valid: false,
      is_forever: false,
      errorObj: {},
      isInvalidPlanCatId: false,
      fk_cat_id: '',
      doctorList:[],
      selectedDoctor:null,
      warnDoctorNotSpecified:false,
      isLoadingTypeahead:false,
    });
  };

  validateForm = () => {
    const { days, name, description, amount, errorObj } = this.state;

    if (!validateString(name)) {
      this.setState({
        errorObj: { ...errorObj, name: invalidTextMessage },
        name: '',
      });
      return false;
    }
    if (!validateString(description)) {
      this.setState({
        errorObj: { ...errorObj, description: invalidTextMessage },
        description: '',
      });
      return false;
    }
    if (!validateNonNegative(days)) {
      this.setState({
        errorObj: { ...errorObj, days: invalidNumberMessage },
        days: 0,
      });
      return false;
    }
    if (!validateNonNegative(amount)) {
      this.setState({
        errorObj: { ...errorObj, amount: invalidNumberMessage },
        amount: 0,
      });
      return false;
    } else {
      return true;
    }
  };

  getPlanList = async (offset = 0) => {
    const requestObj = {
      path: `zivovAuthenticatedRoutes/get_token_plan`,
      method: 'get',
      params: { offset: offset, limit: this.pageSize },
    };
    initAPICall(requestObj)
      .then(succResp => {
        console.log(succResp.data.response.tokenPlan);
        this.setState({
          planList: succResp.data.response.tokenPlan,
          pagesCount: Math.ceil(succResp.data.response.total / this.pageSize),
        });
      })
      .catch(error => {
        const alertMessage = getErrorMessage(error);
        const errorObj = defaultDialogProps(
          'Error',
          alertMessage,
          'OK',
          () => {},
        ); // last is callback function
        this.props.alertContext[1](errorObj);
      })
      .finally(() => {
        this.props.loaderContext[1]({ open: false });
      });
  };

  insert = () => {
    const { days, planList, name, description, amount, fk_cat_id, selectedDoctor } = this.state;
    if (!this.validateForm()) return;
    this.props.loaderContext[1]({ open: true });
    const requestObj = {
      path: 'zivovAuthenticatedRoutes/add_token_plan',
      method: 'post',
      params: {
        days: parseInt(days),
        amount: parseInt(amount),
        name: name,
        description: description,
        fk_activation_plan_category_id: fk_cat_id,
        fk_g_doctor_id:selectedDoctor?.id

      },
    };
    initAPICall(requestObj)
      .then(succResp => {
        if (succResp.status === 200) {
          this.getPlanList();
        } else {
          const errorObj = defaultDialogProps(
            'Error',
            'Could not insert to database. Please try again or refresh page.',
            'OK',
            () => {},
          );
          this.props.alertContext[1](errorObj);
        }
      })
      .catch(error => {
        const alertMessage = getErrorMessage(error);
        const errorObj = defaultDialogProps(
          'Error',
          alertMessage,
          'OK',
          () => {},
        );
        this.props.alertContext[1](errorObj);
      })
      .finally(() => {
        this.props.loaderContext[1]({ open: false });
      });
    this.resetFormFields();
  };

  toggleSideBar = () => {
    this.setState({ showSideBar: !this.state.showSideBar });
  };

  enableUpdate = index => {
    const { planList } = this.state;
    const editObj = planList[index];
    this.setState({
      editIndex: index,
      days: editObj.days,
      name: editObj.name,
      description: editObj.description,
      amount: editObj.amount,
      showSideBar: true,
      fk_cat_id: editObj.fk_activation_plan_category_id,
      is_active:editObj.is_active,
    });
  };

  cancelUpdate = () => {
    this.resetFormFields();
  };

  handleSwitch = (val, id, index) => {
    this.props.loaderContext[1]({ open: true });
    const { planList } = this.state;
    console.log('hi', val);
    initAPICall({
      path: 'zivovAuthenticatedRoutes/update_token_Plan',
      method: 'post',
      params: {
        planTokenId: planList[index].id,
        name: planList[index].name,
        amount: planList[index].amount,
        days: planList[index].days,
        description: planList[index].description,
        is_active: val,
      },
    })
      .then(({ data }) => {
        if (data.isSuccessful) {
          let newObj = planList[index];
          newObj['is_active'] = val;

          let newList = planList;
          newList.splice(index, 1, newObj);
          console.log(newList);
          setTimeout(
            this.setState(prevState => {
              return {
                ...prevState,
                ['planList']: newList,
              };
            }),
            1000,
          );
        }
      })
      .catch(error => {
        const alertMessage = getErrorMessage(error);
        const errorObj = defaultDialogProps(
          'Error',
          alertMessage,
          'OK',
          () => {},
        );
        return errorObj;
      })
      .finally(() => {
        this.props.loaderContext[1]({ open: false });
      });
  };


  saveUpdate = () => {
    const { days, planList, editIndex, name, description, amount,is_active } = this.state;
    if (!this.validateForm()) return;
    this.props.loaderContext[1]({ open: true });
    const requestObj = {
      path: 'zivovAuthenticatedRoutes/update_token_Plan',
      method: 'post',
      params: {
        planTokenId: planList[editIndex].id,
        name: name,
        amount: amount,
        days: days,
        description: description,
        is_active:is_active,
      },
    };
    initAPICall(requestObj)
      .then(succResp => {
        if (succResp.status === 200) {
          planList.splice(editIndex, 1);
          setTimeout(
            this.setState({
              planList: [succResp.data.response.tokenPlan, ...planList],
            }),
            1000,
          );
        } else {
          const errorObj = defaultDialogProps(
            'Error',
            'Could not update. Please try again or refresh page.',
            'OK',
            () => {},
          );
          this.props.alertContext[1](errorObj);
        }
      })
      .catch(error => {
        const alertMessage = getErrorMessage(error);
        const errorObj = defaultDialogProps(
          'Error',
          alertMessage,
          'OK',
          () => {},
        );
        this.props.alertContext[1](errorObj);
      })
      .finally(() => {
        this.props.loaderContext[1]({ open: false });
      });
    this.resetFormFields();
  };

  handleOnChange = event => {
    const { errorObj } = this.state;
    this.setState({
      [event.target.name]: event.target.value,
      errorObj: { ...errorObj, [event.target.name]: '' },
    });
    //setTimeout(console.log(this.state), 2000);
  };

  componentDidMount() {
    this.getPlanList();
    this.getPlanCategoryList();
  }

  handleClick(e, index) {
    e.preventDefault();
    const offset = index * this.pageSize;
    this.getPlanList(offset);
    this.setState({
      currentPage: index,
    });
  }

  render() {
    const {
      days,
      isInvalidDays,
      name,
      isInvalidName,
      description,
      isInvalidDescription,
      amount,
      isInvalidAmount,
      planList,
      editIndex,
      currentPage,
      pagesCount,
      showSideBar,
      errorObj,
      fk_cat_id,
      planCategoryList,
      isInvalidPlanCatId,
      warnDoctorNotSpecified,
      doctorList,
      isLoadingTypeahead,
      modal,
      modalData,
    
    } = this.state;
    const editMode = editIndex !== -1;
    return (
      <Page
        breadcrumbs={[
          { name: 'Global Tables', active: true },
          { name: 'App Access Token', active: true },
        ]}
      >
        <Row style={{ padding: 15 }}>
          <ButtonGroup>
            {showSideBar ? (
              <Button
                type="button"
                color="success"
                onClick={this.toggleSideBar}
              >
                Hide
              </Button>
            ) : (
              <Button
                type="button"
                color="success"
                onClick={this.toggleSideBar}
              >
                Add Plan
              </Button>
            )}
          </ButtonGroup>
        </Row>
        <Row>
          {showSideBar && (
            <>
              <Col>
                <Card>
                  <CardHeader>
                    {editMode ? 'Edit' : 'New'} Activation Plan
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Label for="months">Name *</Label>
                        <Input
                          required
                          name="name"
                          placeholder="Enter Name..."
                          invalid={isInvalidName}
                          value={name}
                          onChange={this.handleOnChange}
                        />
                        <div style={{ color: 'red' }}>{errorObj?.name}</div>
                      </FormGroup>
                      <FormGroup>
                        <Label for="months">Description *</Label>
                        <Input
                          required
                          name="description"
                          placeholder="Enter Description..."
                          invalid={isInvalidDescription}
                          value={description}
                          onChange={this.handleOnChange}
                        />
                        <div style={{ color: 'red' }}>
                          {errorObj?.description}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label for="fk_cat_id">Select a Plan Category*</Label>
                        <Input
                          required
                          type="select"
                          name="fk_cat_id"
                          invalid={isInvalidPlanCatId}
                          value={fk_cat_id}
                          onChange={this.handleOnChange}
                        >
                          <option value="">Select Plan . . .</option>
                          {planCategoryList.map((value, index) => (
                            <option key={index} value={value.id}>
                              {value.name}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>
                          Please select a Plan Category
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="days">Days *</Label>
                        <Input
                          required
                          type="number"
                          name="days"
                          placeholder="Enter Days..."
                          invalid={isInvalidDays}
                          value={days}
                          onChange={this.handleOnChange}
                        />
                        <div style={{ color: 'red' }}>{errorObj?.days}</div>
                      </FormGroup>
                      <FormGroup>
                    <Label for="async-typeahead-doctor">
                      Select A Doctor
                      {isLoadingTypeahead && (
                        <Spinner size="sm" style={{ marginBottom: '0.2rem' }} />
                      )}
                      {warnDoctorNotSpecified && (
                        <div
                          style={{
                            fontSize: '0.9rem',
                            color: 'red',
                            fontStyle: 'italic',
                          }}
                        >
                          * Please choose a Zivov user.
                        </div>
                      )}
                    </Label>
                    <AsyncTypeahead
                      filterBy={this.filterBy}
                      id="async-typeahead-doctor"
                      isLoading={false}
                      labelKey="login"
                      minLength={3}
                      name="async-typeahead-doctor"
                      onBlur={() => {
                        if (!this.state.selectedDoctor) {
                          this.typeaheadRef.clear();
                        }
                      }} // clear the field if not properly selected a user
                      onChange={this.handleSelectedDoctor} // update selectedUser in state
                      onSearch={this.handleSearch}
                      options={doctorList}
                      placeholder="Search for a Zivov doctor (enter at least three characters) . . ."
                      ref={typeaheadRef => {
                        this.typeaheadRef = typeaheadRef;
                      }} // for onBlur to work properly
                      renderMenuItemChildren={(doctor, props) => (
                        <div>
                           <span>
                            {' '}
                            <b>{doctor.login}</b>{' '}
                          </span>
                        </div>
                      )}
                    />
                  </FormGroup> 
                      <FormGroup>
                        <Label for="years">Amount *</Label>
                        <Input
                          required
                          type="number"
                          name="amount"
                          placeholder="Enter Amount..."
                          invalid={isInvalidAmount}
                          value={amount}
                          onChange={this.handleOnChange}
                        />
                        <div style={{ color: 'red' }}>{errorObj?.amount}</div>
                      </FormGroup>
                      <br />
                      <ButtonGroup style={{ float: 'right' }}>
                        {editMode ? (
                          <>
                            <Button
                              type="button"
                              color="success"
                              onClick={this.saveUpdate}
                            >
                              Save
                            </Button>
                            <Button
                              type="button"
                              color="warning"
                              onClick={this.cancelUpdate}
                            >
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <Button
                            type="button"
                            color="success"
                            onClick={this.insert}
                          >
                            Add Plan
                          </Button>
                        )}
                      </ButtonGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}

          <Col xl={showSideBar && 8} lg={12} md={12}>
            <Card>
              <CardHeader>Activation Plans</CardHeader>
              <CardBody>
                <Table hover responsive size="sm">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>Plan Link</th>
                      <th>Description</th>
                      <th>Amount</th>
                      <th>Doctor</th>
                      <th>Days</th>
                      <th>Active</th>
                      <th id="appAccessTokenValidThrough">Updated On</th>
                      <th id="appAccessTokenValidThrough">Created On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {planList.map((value, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{value.name}</td>
                        <td>{value?.activation_plan_category?.name}</td>
                        <td>{value.fk_g_doctor_id && <> 
                          <a href={`https://zivov.com/docPlans/${value.fk_activation_plan_category_id}/${value.fk_g_doctor_id}`} target="_blank">link</a>
                          <MdContentCopy
                                onClick={() =>
                                  navigator.clipboard.writeText(`https://zivov.com/docPlans/${value.fk_activation_plan_category_id}/${value.fk_g_doctor_id}`)
                                }
                              />
                        </>}</td>
                        <td>{value.description}</td>
                        <td>{value.amount}</td>
                        <td>{value.fk_g_doctor_id &&
                         <Button
                         onClick={() => {
                           this.setState({
                             modalData: value?.g_doctor,
                             modal: true,
                           });
                         }}
                       >
                         Doctor
                       </Button>}</td>
                        <td>{value.days}</td>
                        <td>
                          <div>
                            <CustomInput
                              type="switch"
                              id={`editTypeSwitch_${value?.id}`} // required
                              checked={value?.is_active}
                              onChange={({ target }) =>
                                this.handleSwitch(
                                  target.checked,
                                  value?.id,
                                  index,
                                )
                              }
                            />
                          </div>
                        </td>
                        <td>
                          {new Date(value.updated_on).toLocaleDateString(
                            'en-IN',
                            DateOptions2,
                          )}
                        </td>
                        <td>
                          {new Date(value.created_on).toLocaleDateString(
                            'en-IN',
                            DateOptions2,
                          )}
                        </td>
                        <td>
                          <ButtonGroup className="mr-3 mb-3" size="sm">
                            <Button
                              color="warning"
                              id="Edit"
                              onClick={() => this.enableUpdate(index)}
                            >
                              <MdEdit />
                            </Button>
                            <UncontrolledTooltip target="Edit">
                              Edit
                            </UncontrolledTooltip>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Modal isOpen={modal} toggle={this.modalToggle}>
                    <ModalHeader toggle={this.modalToggle}>
                    Doctor
                  </ModalHeader>
                   <ModalBody>
                    <Row>
                      <Col>Name1: </Col>
                      <Col>{modalData.name}</Col>
                    </Row>
                    <Row>
                      <Col>Contact: </Col>
                      <Col>{modalData.contact_number}</Col>
                    </Row>
                    <Row>
                      <Col>Address: </Col>
                      <Col>{modalData.address}</Col>
                    </Row>
                  </ModalBody>  
                  </Modal>
              </CardBody>
            </Card>
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => (
                <PaginationItem active={i === currentPage} key={i}>
                  <PaginationLink
                    onClick={e => this.handleClick(e, i)}
                    href="#"
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default CreatePlan;
