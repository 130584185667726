export const ServerCofiguration = {
  ENVIRONMENT: "PROD",
  SERVER:{
    DEV: {
      URL: "https://zhi-dev-api.zivov.com:443/",
     //URL: "http://localhost:3000/",
    },
    PROD: {
      URL: "https://zhi-prod-api.zivov.com:443/", 
      //URL:"https://zhi-dev-internal-api.zivov.com:443/",
    }
  }
}

export const SecretKey = 'secret key 123';
