import logoImage from '../../assets/img/logo/logo.png';
import React, { Component } from 'react';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdLocationCity,
  MdLocationOn,
  MdNotificationsActive,
  MdPages,
  MdPeople,
  MdDelete,
  MdRadioButtonChecked,
  MdSettings,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
  MdNfc,
  MdAccountBalance,
  MdError,
  MdEmail,
  MdMessage,
  MdPieChart,
  MdBusinessCenter,
  MdFileDownload
} from 'react-icons/md';

import { NavLink } from 'react-router-dom';
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { AUTH } from '../../utils/Util';

const DoctorOptions = [
  { to: "/add_doctor", name: 'Add Doctor', exact: false, Icon: MdPeople },
  { to: '/view_doctor', name: 'View Doctors', exact: false, Icon: MdPeople },
  { to: '/view_unauthenticate_doctors', name: 'Unauthenticated Doctors', exact: false, Icon: MdPeople },
  {
    to: '/view_refer_doctor',
    name: 'Refers Doctors',
    exact: false,
    Icon: MdPeople,
  },
  {
    to: '/patient_doctor_mapping',
    name: 'Patient Doctor Mapping',
    exact: false,
    Icon: MdPeople,
  },
  {
    to: '/doctor_patient_mapping',
    name: 'Doctor Patient Mapping',
    exact: false,
    Icon: MdPeople,
  },
];

const Users = [
  { to: '/view_users', name: 'View Users', exact: false, Icon: MdPeople },
  { to: '/view_Deleted_Users', name: 'View Deleted Users', exact: false, Icon: MdDelete },
  {
    to: '/view_unauthenticate_user',
    name: 'Unauthenticate Users',
    exact: false,
    Icon: MdPeople,
  },
  {
    to: '/notification_logs',
    name: 'Notification Logs',
    exact: false,
    Icon: MdViewList,
  },
  {
    to: '/contact_us_info',
    name: 'ContactInfo',
    exact: false,
    Icon: MdViewList,
  },
];

const Hospital = [
  {
    to: '/view_hospital',
    name: 'View Hospitals',
    exact: false,
    Icon: MdPeople,
  },
  { to: '/add_hospital', name: 'Add Hospital', exact: false, Icon: MdPeople },
];

const WebsiteOptions = [
  {
    to: '/website_users',
    name: 'Website Users',
    exact: false,
    Icon: MdNotificationsActive,
  },
  {
    to: '/web_contacts',
    name: 'Website Contacts',
    exact: false,
    Icon: MdPeople,
  },
];
const policyArray = [
  {
    to: '/viewPrivacyPolicy',
    name: 'Privacy Policy',
    exact: false,
    Icon: MdPages,
    params: { title: "Privacy Policy", pageType: "privacy" }
  },
  {
    to: '/viewTermsAndCondition',
    name: 'Terms & Conditon',
    exact: false,
    Icon: MdPages,
    params: { title: "Terms & Conditon", pageType: "terms" }
  }
];

const LocationOptions = [
  {
    to: '/add_g_country',
    name: 'Countries',
    exact: false,
    Icon: MdLocationCity,
  },
  { to: '/add_g_state', name: 'States', exact: false, Icon: MdLocationCity },
  { to: '/add_g_city', name: 'Cities', exact: false, Icon: MdLocationCity },
];

const QuizOptions = [
  {
    to: '/create_quiz',
    name: 'Create and view Quiz',
    exact: false,
    Icon: MdDashboard,
  },
  {
    to: '/assign_quiz',
    name: 'Assign and Unassign Quiz',
    exact: false,
    Icon: MdDashboard,
  },
  {
    to: '/quiz_results',
    name: 'Quiz Results',
    exact: false,
    Icon: MdDashboard,
  },
];

const HealthScoreOptions = [
  {
    to: '/health_questions',
    name: 'Health Questions',
    exact: false,
    Icon: MdDashboard,
  },
];

const VideoSeriesOptions = [
  {
    to: '/video_series',
    name: 'Video Series',
    exact: false,
    Icon: MdDashboard,
  },
];

const PointCategoryOptions = [
  {
    to: '/point_category',
    name: 'Point Category',
    exact: false,
    Icon: MdDashboard,
  },
  {
    to: '/point_value',
    name: 'Point Value',
    exact: false,
    Icon: MdDashboard,
  },
];

const ProductOptions = [
  {
    to: '/product_category',
    name: 'Product Category',
    exact: false,
    Icon: MdDashboard,
  },

]


const RewardsOptions = [
  {
    to: '/create_rewards',
    name: 'Create and view Rewards',
    exact: false,
    Icon: MdDashboard,
  },
];

const DietPlanOptions = [
  {
    to: '/add_g_diet_plan',
    name: 'View Assigned Diet Plans',
    exact: false,
    Icon: MdDashboard,
  },
  {
    to: '/add_g_add_diet_plan',
    name: 'Assign Diet Plan to User',
    exact: false,
    Icon: MdDashboard,
  },
  {
    to: '/add_g_create_or_edit_a_new_diet_plan',
    name: 'Create/Edit Diet Plan',
    exact: false,
    Icon: MdDashboard,
  },
  {
    to: '/add_g_diet_plan_name',
    name: 'Create New Diet Plan Name',
    exact: false,
    Icon: MdDashboard,
  },
];
const FoodOptions = [
  {
    to: '/add_g_view_food',
    name: 'View Foods',
    exact: false,
    Icon: MdDashboard,
  },
  { to: '/add_g_add_food', name: 'Add Food', exact: false, Icon: MdDashboard },
];

const ChallengeOptions = [
  {
    to: '/challenges',
    name: 'Challenges',
    exact: false,
    Icon: MdDashboard,
  },
];

// const Configuration = [
//   { to: '/add_refer_by_type', name: 'Refer By Types', exact: false, Icon: MdWeb },
// ];

const tempSampleOptions = [
  { to: '/cards', name: 'cards', exact: false, Icon: MdWeb },
  { to: '/charts', name: 'charts', exact: false, Icon: MdInsertChart },
  { to: '/widgets', name: 'widgets', exact: false, Icon: MdWidgets },
  { to: '/buttons', name: 'buttons', exact: false, Icon: MdRadioButtonChecked },
  {
    to: '/button-groups',
    name: 'button groups',
    exact: false,
    Icon: MdGroupWork,
  },
  { to: '/forms', name: 'forms', exact: false, Icon: MdChromeReaderMode },
  { to: '/input-groups', name: 'input groups', exact: false, Icon: MdViewList },
  {
    to: '/dropdowns',
    name: 'dropdowns',
    exact: false,
    Icon: MdArrowDropDownCircle,
  },
  { to: '/badges', name: 'badges', exact: false, Icon: MdStar },
  { to: '/alerts', name: 'alerts', exact: false, Icon: MdNotificationsActive },
  { to: '/progress', name: 'progress', exact: false, Icon: MdBrush },
  { to: '/modals', name: 'modals', exact: false, Icon: MdViewDay },
  { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
  { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
  { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
  {
    to: '/login-modal',
    name: 'login modal',
    exact: false,
    Icon: MdViewCarousel,
  },
];

// Goals_groups

const GoalOptions = [
  { to: '/add_g_goals', name: 'Goals', exact: false, Icon: MdLocationCity },
  //{ to: '/add_g_groups', name: 'Groups', exact: false, Icon: MdLocationCity },
  {
    to: '/goal_groups',
    name: 'Goal Groups',
    exact: false,
    Icon: MdLocationCity,
  },

  {
    to: '/assgn_goal_to_group',
    name: 'Assign Goals To Group',
    exact: false,
    Icon: MdLocationCity,
  },
  {
    to: '/assgn_group_to_user',
    name: 'Assign Goals To User',
    exact: false,
    Icon: MdLocationCity,
  },
  {
    to: '/view_user_group_mapping',
    name: 'View User Goals',
    exact: false,
    Icon: MdLocationCity,
  },
];

// Access Token
const AccessTokenOptions = [

  {
    to: '/add_plan_category',
    name: 'Plan Category',
    exact: false,
    Icon: MdLocationCity,
  },
  { to: '/add_plan', name: 'Create Plan', exact: false, Icon: MdLocationCity },
  {
    to: '/add_rebate_coupon',
    name: 'Create Rebate Coupon',
    exact: false,
    Icon: MdLocationCity,
  },
  {
    to: '/add_access_token',
    name: 'Create Access Token',
    exact: false,
    Icon: MdLocationCity,
  },
];
//create_channel
const ReferalCodeOptions = [
  {
    to: '/create_channel',
    name: 'Create Channel',
    exact: false,
    Icon: MdLocationCity,
  },
  {
    to: '/referal_code',
    name: 'Create Referal Code',
    exact: false,
    Icon: MdLocationCity,
  },
];

// Backend User
const BackendUserOptions = [
  {
    to: '/view_backend_user',
    name: 'View Users',
    exact: false,
    Icon: MdLocationCity,
  },
  {
    to: '/add_backend_user',
    name: 'Add User',
    exact: false,
    Icon: MdLocationCity,
  },
  {
    to: '/manage_access',
    name: 'Manage Access',
    exact: false,
    Icon: MdLocationCity,
  }
];

// yoga Session
const YogaSessionOptions = [
  {
    to: '/view_yoga_session',
    name: 'View/Add Yoga Session',
    exact: false,
    Icon: MdLocationCity,
  },
  {
    to: '/view_yoga_session_logs',
    name: 'Yoga Session Logs',
    exact: false,
    Icon: MdLocationCity,
  },
];

// yoga Session

const DietConsultationSessionOptions = [
  {
    to: '/view_diet_consultation_session',
    name: 'View/Add Diet Consultation Session',
    exact: false,
    Icon: MdLocationCity,
  },
];

const bem = bn.create('sidebar');

const DropdownTransform = {
  padding: 0,
  transitionDuration: '0.3s',
  transitionProperty: 'transform',
};



class Sidebar extends Component {
  state = {
    isOpenDashboardMenuOptions: false,
    isOpenContents: false,
    isOpensampleCodeOption: false,
    // isOpenGlobalTables: false,
    isOpenConfig: false,
    isOpenDietPlanOptions: false,
    isOpenFoodOptions: false,
    isOpenLocationOptions: false,
    isOpenPolicyOptions: false,
    isOpenDoctorOptions: false,
    isOpenHospitalOptions: false,
    isOpenUsersOptions: false,
    isOpenGoalOptions: false,
    isOpenAccessTokenOptions: false,
    isOpenReferalCodeOptions: false,
    isOpenBackendUserOptions: false,
    isOpenYogaSessionOptions: false,
    isOpenDietConsultationSessionOptions: false,
    isOpenWebsiteOptions: false,
    isOpenQuizOptions: false,
    isOpenHealthScoreOptions: false,
    isOpenVideoSeriesOptions: false,
    isOpenPointCategoryOptions: false,
    isOpenProductOptions: false,
    isOpenRewardOptions: false,
    isOpenChallengeOptions: false,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return { [`isOpen${name}`]: !isOpen };
    });
  };

  render() {
    const { user } = AUTH;
    let userType = 'administrator';
    if (user) {
      userType = user.userType;
    }
    console.log('llaa', userType);

    const NavItems = [
      {
        to: '/dashboard',
        name: 'Dashboard',
        exact: true,
        Icon: MdDashboard,
        visible: userType === 'administrator',
      },
      {
        to: '/change_admin_password',
        name: 'Change Password',
        exact: true,
        Icon: MdDashboard,
        visible: userType === 'administrator',
      },
      // { to: '/', name: 'Send Notifications', exact: false, Icon: MdNotificationsActive },

      {
        to: '/view_payment_logs',
        name: 'Doctor Payment',
        exact: false,
        Icon: MdNotificationsActive,
        visible: userType === 'administrator',
      },
      {
        to: '/view_sms_logs',
        name: 'Auth SMS',
        exact: false,
        Icon: MdNotificationsActive,
        visible: (userType === 'administrator' || userType === 'dietician')
      },
      {
        to: '/lab_report_parser',
        name: 'Lab Report Parser',
        exact: false,
        Icon: MdNotificationsActive,
        visible: userType === 'administrator',
      },
      {
        to: '/dietician_user_mapping',
        name: 'Dietician User Mapping',
        exact: false,
        Icon: MdNotificationsActive,
        visible: userType === 'administrator',
      },
      {
        to: '/view_blog',
        name: 'Blog',
        exact: false,
        Icon: MdNotificationsActive,
        visible: userType === 'administrator' //|| userType === 'Website Admin',
      },
      {
        to: '/getSensorCount',
        name: 'Remaining Sensor',
        exact: false,
        Icon: MdPieChart,
        visible: userType === 'administrator',
      },
      {
        to: '/sensor_activation',
        name: 'Sensor access code',
        exact: false,
        Icon: MdBusinessCenter,
        visible: (userType === 'administrator' || userType === 'dietician')
      },
      {
        to: '/sensor_sales',
        name: 'Sensor Orders',
        exact: false,
        Icon: MdAccountBalance,
        visible: (userType === 'administrator' || userType === 'order_management' || userType === 'dietician') ? true : false,
      },
      {
        to: '/sensorError',
        name: 'Sensor Error',
        exact: false,
        Icon: MdError,
        visible: (userType === 'administrator' || userType === 'dietician') ? true : false,
      },
      {
        to: '/downloadSensorCodes',
        name: 'Download Senrsor codes',
        exact: false,
        Icon: MdFileDownload,
        visible: (userType === 'administrator') ? true : false,
      },
      {
        to: '/sendEmail',
        name: 'Send Email',
        exact: false,
        Icon: MdEmail,
        visible: userType === 'administrator',
      },
      {
        to: '/sendWhatsapp',
        name: 'Send Whatsapp',
        exact: false,
        Icon: MdMessage,
        visible: userType === 'administrator',
      }

    ];
    const {
      // isOpenGlobalTables,
      isOpenDashboardMenuOptions,
      isOpenConfig,
      isOpensampleCodeOption,
      isOpenLocationOptions,
      isOpenPolicyOptions,
      isOpenDietPlanOptions,
      isOpenFoodOptions,
      isOpenHospitalOptions,
      isOpenDoctorOptions,
      isOpenUsersOptions,
      isOpenGoalOptions,
      isOpenAccessTokenOptions,
      isOpenReferalCodeOptions,
      isOpenBackendUserOptions,
      isOpenYogaSessionOptions,
      isOpenDietConsultationSessionOptions,
      isOpenWebsiteOptions,
      isOpenQuizOptions,
      isOpenHealthScoreOptions,
      isOpenVideoSeriesOptions,
      isOpenPointCategoryOptions,
      isOpenProductOptions,
      isOpenRewardOptions,
      isOpenChallengeOptions,
    } = this.state;

    return (
      <aside className={bem.b()}>
        <div className={bem.e('content')}>
          <Navbar>
            <img
              src={logoImage}
              width="40"
              height="30"
              className="pr-2"
              alt=""
            />
            <span className="headerTitle">Zivov</span>
          </Navbar>

          <Nav vertical>
            {NavItems.map(
              ({ to, name, exact, Icon, visible }, index) =>
                visible && (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}

                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ),
            )}

            {/* <NavItem className={bem.e('nav-item')} onClick={this.handleClick('GlobalTables')}>
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} style={{ paddingBottom: 5 }} />
                  <span className=" align-self-start">Global Tables</span>
                </div>
                <MdKeyboardArrowDown className={bem.e('nav-item-icon')}
                  style={{ ...DropdownTransform, transform: `rotate(${isOpenGlobalTables ? '0' : '-90'}deg)` }} />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={isOpenGlobalTables}>
              {GlobalTables.map(({ to, name, exact, Icon }, index) => (

                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink id={`navItem-${name}-${index}`} tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    &emsp;&emsp;
                      <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>

              ))}
            </Collapse> */}

            {/* <NavItem className={bem.e('nav-item')} onClick={this.handleClick('DashboardMenuOptions')}>
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} style={{ paddingBottom: 5 }} />
                  <span className=" align-self-start">App Options</span>
                </div>
                <MdKeyboardArrowDown className={bem.e('nav-item-icon')}
                  style={{ ...DropdownTransform, transform: `rotate(${isOpenDashboardMenuOptions ? '0' : '-90'}deg)` }} />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={isOpenDashboardMenuOptions}>
              {DashboardMenuOptions.map(({ to, name, exact, Icon }, index) => (

                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink id={`navItem-${name}-${index}`} tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    &emsp;&emsp;
                      <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>

              ))}
            </Collapse> */}

            {(userType === 'administrator' || userType === 'Website Admin') && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('QuizOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className="">Quiz</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenQuizOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenQuizOptions}>
                  {QuizOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>

                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('HealthScoreOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className="">Health Score</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenHealthScoreOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenHealthScoreOptions}>
                  {HealthScoreOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('VideoSeriesOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className="">Video Series</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenVideoSeriesOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenVideoSeriesOptions}>
                  {VideoSeriesOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('ChallengeOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className="">Challenges</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenChallengeOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenChallengeOptions}>
                  {ChallengeOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('PointCategoryOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className="">Points (Game) </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenPointCategoryOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenPointCategoryOptions}>
                  {PointCategoryOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>

                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('ProductOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className="">Products </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenProductOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenProductOptions}>
                  {ProductOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </>
            )}


            {userType === 'administrator' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('LocationOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdLocationOn
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className="">Location</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenLocationOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenLocationOptions}>
                  {LocationOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>



                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('PolicyOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdGroupWork
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className="">Policies</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenPolicyOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenPolicyOptions}>
                  {policyArray.map(({ to, name, exact, Icon, params }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={
                          {
                            pathname: to,
                            aboutProps: params
                          }
                        }
                        activeClassName="active"
                        exact={exact}
                      // aboutProps={{ pass: 'some data' }}

                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>


                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('WebsiteOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdLocationOn
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className="">Website</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenWebsiteOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenWebsiteOptions}>
                  {WebsiteOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('RewardOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className="">Rewards</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenRewardOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenRewardOptions}>
                  {RewardsOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </>
            )}


            {/* <NavItem className={bem.e('nav-item')} onClick={this.handleClick('Config')}>
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSettings className={bem.e('nav-item-icon')} style={{ paddingBottom: 5 }} />
                  <span className="">Configuration</span>
                </div>
                <MdKeyboardArrowDown className={bem.e('nav-item-icon')}
                  style={{ ...DropdownTransform, transform: `rotate(${isOpenConfig ? '0' : '-90'}deg)` }} />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={isOpenConfig}>
              {Configuration.map(({ to, name, exact, Icon }, index) => (

                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink id={`navItem-${name}-${index}`} tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    &emsp;&emsp;
                      <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>

              ))}
            </Collapse> */}

            {/* <NavItem className={bem.e('nav-item')} onClick={this.handleClick('sampleCodeOption')}>
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdPages className={bem.e('nav-item-icon')} style={{ paddingBottom: 5 }} />
                  <span className="">Sample Design Options</span>
                </div>
                <MdKeyboardArrowDown className={bem.e('nav-item-icon')}
                  style={{ ...DropdownTransform, transform: `rotate(${isOpensampleCodeOption ? '0' : '-90'}deg)` }} />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={isOpensampleCodeOption}>
              {tempSampleOptions.map(({ to, name, exact, Icon }, index) => (

                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink id={`navItem-${name}-${index}`} tag={NavLink} to={to} activeClassName="active" exact={exact}>
                    &emsp;&emsp;
                      <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>

              ))}
            </Collapse> */}
            {(userType === 'administrator' || userType === 'dietician' || userType === 'external_dietician') && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('DietPlanOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className=" align-self-start">Diet Plan</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenDashboardMenuOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenDietPlanOptions}>
                  {DietPlanOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </>
            )}
            {(userType === 'administrator' || userType === 'Food Admin') && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('FoodOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className=" align-self-start">Food</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenDashboardMenuOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenFoodOptions}>
                  {FoodOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </>
            )}
            {userType === 'administrator' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('DoctorOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className=" align-self-start">Doctors</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenDoctorOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>

                <Collapse isOpen={isOpenDoctorOptions}>
                  {DoctorOptions.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </>
            )}
            {(userType === 'administrator' || userType === 'dietician' || userType === 'external_dietician') && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('UsersOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className=" align-self-start">Users</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenUsersOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenUsersOptions}>
                  {Users.map(({ to, name, exact, Icon }, index) => {
                    if ((userType !== 'dietician' && userType !== 'external_dietician') || index === 0) {
                      return (
                        <NavItem key={index} className={bem.e('nav-item')}>
                          <BSNavLink
                            id={`navItem-${name}-${index}`}
                            tag={NavLink}
                            to={to}
                            activeClassName="active"
                            exact={exact}
                          >
                            &emsp;&emsp;
                            <Icon className={bem.e('nav-item-icon')} />
                            <span className="">{name}</span>
                          </BSNavLink>
                        </NavItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Collapse>
              </>
            )}
            {userType === 'administrator' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('HospitalOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className=" align-self-start">Hospitals</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenHospitalOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenHospitalOptions}>
                  {Hospital.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        &emsp;&emsp;
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </>
            )}
            {(userType === 'administrator' ||
              userType === 'Fitness Instructor' || userType === 'dietician') && (
                <>
                  <NavItem
                    className={bem.e('nav-item')}
                    onClick={this.handleClick('GoalOptions')}
                  >
                    <BSNavLink className={bem.e('nav-item-collapse')}>
                      <div className="d-flex">
                        <MdExtension
                          className={bem.e('nav-item-icon')}
                          style={{ paddingBottom: 5 }}
                        />
                        <span className=" align-self-start">Goals</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e('nav-item-icon')}
                        style={{
                          ...DropdownTransform,
                          transform: `rotate(${isOpenHospitalOptions ? '0' : '-90'
                            }deg)`,
                        }}
                      />
                    </BSNavLink>
                  </NavItem>
                  <Collapse isOpen={isOpenGoalOptions}>
                    {GoalOptions.map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e('nav-item')}>
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          &emsp;&emsp;
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ))}
                  </Collapse>
                </>
              )}
            {userType === 'administrator' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('AccessTokenOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className=" align-self-start">Access Token</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenHospitalOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenAccessTokenOptions}>
                  {AccessTokenOptions.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e('nav-item')}>
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          &emsp;&emsp;
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
              </>
            )}
            {userType === 'administrator' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('ReferalCodeOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className=" align-self-start">Referal Code</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenHospitalOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenReferalCodeOptions}>
                  {ReferalCodeOptions.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e('nav-item')}>
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          &emsp;&emsp;
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
              </>
            )}
            {userType === 'administrator' && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('BackendUserOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className=" align-self-start">Backend User</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenHospitalOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenBackendUserOptions}>
                  {BackendUserOptions.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e('nav-item')}>
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          &emsp;&emsp;
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
              </>
            )}
            {(userType === 'administrator' ||
              userType === 'yoga Instructor') && (
                <>
                  <NavItem
                    className={bem.e('nav-item')}
                    onClick={this.handleClick('YogaSessionOptions')}
                  >
                    <BSNavLink className={bem.e('nav-item-collapse')}>
                      <div className="d-flex">
                        <MdExtension
                          className={bem.e('nav-item-icon')}
                          style={{ paddingBottom: 5 }}
                        />
                        <span className=" align-self-start">Yoga Session</span>
                      </div>
                      <MdKeyboardArrowDown
                        className={bem.e('nav-item-icon')}
                        style={{
                          ...DropdownTransform,
                          transform: `rotate(${isOpenHospitalOptions ? '0' : '-90'
                            }deg)`,
                        }}
                      />
                    </BSNavLink>
                  </NavItem>
                  <Collapse isOpen={isOpenYogaSessionOptions}>
                    {YogaSessionOptions.map(
                      ({ to, name, exact, Icon }, index) => (
                        <NavItem key={index} className={bem.e('nav-item')}>
                          <BSNavLink
                            id={`navItem-${name}-${index}`}
                            tag={NavLink}
                            to={to}
                            activeClassName="active"
                            exact={exact}
                          >
                            &emsp;&emsp;
                            <Icon className={bem.e('nav-item-icon')} />
                            <span className="">{name}</span>
                          </BSNavLink>
                        </NavItem>
                      ),
                    )}
                  </Collapse>
                </>
              )}
            {(userType === 'administrator' || userType === 'dietician') && (
              <>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('DietConsultationSessionOptions')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdExtension
                        className={bem.e('nav-item-icon')}
                        style={{ paddingBottom: 5 }}
                      />
                      <span className=" align-self-start">
                        Diet Consultation Session
                      </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        ...DropdownTransform,
                        transform: `rotate(${isOpenHospitalOptions ? '0' : '-90'
                          }deg)`,
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenDietConsultationSessionOptions}>
                  {DietConsultationSessionOptions.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e('nav-item')}>
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          &emsp;&emsp;
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ),
                  )}
                </Collapse>
              </>
            )}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
