import React, { useState, useEffect, memo } from "react";

export default memo(function Page(props) {
  return (
    <props.contextUtil.AlertContextKey.Consumer>
      {contextAlert => (
        <props.contextUtil.ConfirmContextKey.Consumer>
          {contextConfirm => (
            <props.contextUtil.LoaderContextKey.Consumer>
              {contextLoader => (
                <props.component {...props} alertContext={contextAlert} confirmContext={contextConfirm} loaderContext={contextLoader} />
              )}
            </props.contextUtil.LoaderContextKey.Consumer>
          )}
        </props.contextUtil.ConfirmContextKey.Consumer>
      )}
    </props.contextUtil.AlertContextKey.Consumer>
  );
});
